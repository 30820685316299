import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosClient from '../components/AxiosClient';

const VendorRequests = () => {
  const [requests, setRequests] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null); // State to track selected vendor details
  const [requestApproved, setRequestApproved] = useState(false); // State to track if the request is approved

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminToken = localStorage.getItem('adminToken');
    
        if (adminToken) {
          const config = {
            headers: {
              Authorization: `Bearer ${adminToken}`
            }
          };
    
          const response = await axiosClient.post('/onboarding/get_all_onboarding/1/10', null, config);
          setRequests(response.data.data[0]);
        } else {
          console.error('Admin token not found.');
        }
      } catch (error) {
        console.error('Error fetching vendor requests:', error);
      }
    };

    fetchData();
  }, [requestApproved]); 
  const handleViewDetails = (vendorId) => {
    const vendor = requests.find((vendor) => vendor.id === vendorId);
    console.log('vendor',vendor);
    setSelectedVendor(vendor);
  };

  // Function to close the popup and return back
  const handleClosePopup = () => {
    setSelectedVendor(null);
  };

  // Function to approve the vendor request
  const handleApproveRequest = async (vendorId, approve_name) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found.');
        return;
      }
  
      const config = {
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      };
  
      const formData = new FormData();
      formData.append('id', vendorId);
      formData.append('approve_name', approve_name)
  
      const response = await await axiosClient.post('/onboarding/approve_onboarding', formData, config);
      console.log('Vendor request approved:', response.data);
      setRequestApproved(true);
    } catch (error) {
      console.error('Error approving vendor request:', error);
    }
  };

  // Refresh the page after request is approved and close popup
  useEffect(() => {
    if (requestApproved) {
      setTimeout(() => {
        window.location.reload(); // Refresh the page
      }, 2000); // Adjust timeout duration as needed
    }
  }, [requestApproved]);

  return (
    <div className="relative">
      <h2 className="text-2xl font-semibold mb-4">Vendor Onboarding Requests</h2>
      <table className="w-full">
        <thead>
          <tr>
            <th>Serial No.</th>
            <th>Vendor Name</th>
            <th>Mobile</th>
            <th>Payment status</th>
            <th>Action</th> {/* Column for the action button */}
          </tr>
        </thead>
        <tbody>
          {requests.map((request, index) => (
            <tr key={request.id}>
              <td>{index + 1}</td>
              <td>{request.vendor_name}</td>
              <td>{request.mobile}</td>
              <td>{request.payment_status_tracker[0].status}</td>
              <td>
                <button className="text-blue-500 hover:text-blue-700" onClick={() => handleViewDetails(request.id)}>View Details</button> {/* Button to view vendor details */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal or popup to display vendor details */}
      {selectedVendor && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <span className="absolute top-0 right-0 cursor-pointer text-gray-500" onClick={handleClosePopup}>&times;</span> {/* Button to close modal */}
            <h2 className="text-2xl font-semibold mb-4">Vendor Details</h2>
            <p className="text-lg"><strong>Vendor id:</strong> {selectedVendor.id}</p>
            <p className="text-lg"><strong>Vendor Name:</strong> {selectedVendor.vendor_name}</p>
            <p className="text-lg"><strong>Mobile:</strong> {selectedVendor.mobile}</p>
            <p className="text-lg"><strong>Email:</strong> {selectedVendor.email}</p>
            <p className="text-lg"><strong>License No.:</strong> {selectedVendor.license_no}</p>
            <p className="text-lg"><strong>Pharmacy Name:</strong> {selectedVendor.pharmacy_name}</p>
            <p className="text-lg"><strong>Pharmacy Address:</strong> {selectedVendor.pharmacy_address}</p>
            <p className="text-lg"><strong>Pharmacy City:</strong> {selectedVendor.pharmacy_city}</p><p className="text-lg"><strong>Pharmacy State:</strong> {selectedVendor.pharmacy_state}</p>
            <p className="text-lg"><strong>Pharmacy count:</strong> {selectedVendor.pharmacy_count}</p>
            <p className="text-lg"><strong>License Certificate:</strong> {selectedVendor.license_certificate}</p>

            <p className="text-lg"><strong>Pincode:</strong> {selectedVendor.pincode}</p>
            <p className="text-lg"><strong>Legal Entity Name:</strong> {selectedVendor.legal_entity_name}</p>

            <p className="text-lg"><strong>GST Number:</strong> {selectedVendor.gst_number}</p><p className="text-lg"><strong>PAN Number:</strong> {selectedVendor.pan_number}</p><p className="text-lg"><strong>FSSAI Number:</strong> {selectedVendor.fssai_number}</p><p className="text-lg"><strong>Categories:</strong> {selectedVendor.categories}</p><p className="text-lg"><strong>Account Number:</strong> {selectedVendor.account_number}</p><p className="text-lg"><strong>IFSC Code:</strong> {selectedVendor.ifsc_code}</p><p className="text-lg"><strong>Incorporation type:</strong> {selectedVendor.incorporation_type}</p>

            <p className="text-lg"><strong>Payment Status:</strong> {selectedVendor.payment_status_tracker[0].status}</p>
            <div className="mt-4">
              <button className="bg-green-500 text-white px-4 py-2 rounded-md mr-4" onClick={() => handleApproveRequest(selectedVendor.id, "Approve")}>Approve Request</button>
              <button className="bg-green-500 text-white px-4 py-2 rounded-md mr-4" onClick={() => handleApproveRequest(selectedVendor.id, "Disapprove")}>Disapprove Request</button>
              <button className="bg-red-500 text-white px-4 py-2 rounded-md" onClick={handleClosePopup}>Cancel</button> {/* Button to cancel and close the popup */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorRequests;
