import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PopupBlog from '../components/PopupBlog';
import AddBlogPopup from '../components/AddBlogPopup';
import { RiPencilFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import axiosClient from '../components/AxiosClient';


const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [popupInfo, setPopupInfo] = useState(null);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // State for loading indicator

  const openPopup = (info) => {
    setPopupInfo(info);
  };

  const closePopup = () => {
    setPopupInfo(null);
  };

  const openAddPopup = () => {
    setIsAddPopupOpen(true);
  };

  const closeAddPopup = () => {
    setIsAddPopupOpen(false);
  };

  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken");
          
    // Check if token exists
    if (!adminToken) {
      console.error("Admin token not found in localStorage.");
      return;
    }
  
    axiosClient.post('/blogs/get_blogs/1/10', null, {
      headers: {
        'Authorization': `Bearer ${adminToken}`
      }
    })
    .then(response => {
      const blogsData = response.data.map(blog => ({
        id: blog.id,
        title: blog.title,
        content: blog.content,
        description: blog.description,
        // Check if image_data exists before accessing its properties
        imageData: blog.image_url
      }));
      setBlogs(blogsData);
      setIsLoading(false); // Set loading state to false after fetching blogs
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
      setIsLoading(false); // Set loading state to false if there's an error
    });
  }, []);
  

  const handleDelete = (id) => {
    const adminToken = localStorage.getItem("adminToken");
    axiosClient.post(`/blogs/delete_blog/${id}`,null,{
      headers: {
        'Authorization': `Bearer ${adminToken}`
      }
    })
      .then(response => {
        console.log('Blog deleted successfully:', response.data);
        setBlogs(prevBlogs => prevBlogs.filter(blog => blog.id !== id));
      })
      .catch(error => {
        console.error('Error deleting blog:', error);
      });
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(url);
  };
  
  const isPDF = (url) => {
    return /\.pdf$/i.test(url);
  };

  return (
    <div className='rounded-xl p-2 relative shadow-lg'>
      <div className='flex justify-between px-10 pt-8'>
        <div className='text-2xl font-bold'>All Blogs</div>
        <div onClick={openAddPopup} className='cursor-pointer bg-blue-600 text-white py-2 px-4 rounded-md'>Add Blogs</div>
      </div>
      <hr className='mt-2'/>
      {isLoading ? ( // Show loading message if isLoading is true
        <div className="px-8 mt-8"> Blogs are Loading... PLease wait...</div>
      ) : (
        <div className='px-8 mt-8'>
          <div className='flex justify-between font-bold'>
            <div>Image </div>
            <div>Title</div>
            <div>Edit</div>
            <div>Delete</div>
          </div>
          <hr className='mt-2'/>
          {blogs.map(blog => (
            
            <div className='my-10 flex justify-between' key={blog.id}>
              {console.log(blog)}
              {
              isPDF(blog.imageData)?(<iframe
        src={blog.imageData} // Assuming imageData contains the PDF URL
        className='w-[100px]'
        title={blog.title}
      >
        This browser does not support PDFs. Please download the PDF to view it: <a href={blog.imageData}>Download PDF</a>
      </iframe>):
            <img className='w-[100px]' src={blog.imageData} alt={blog.title}/>}
              <div className=''>{blog.title}</div>
              <div className='cursor-pointer text-2xl' onClick={() => openPopup(blog)}><RiPencilFill/></div>
              <div className='text-2xl cursor-pointer' onClick={() => handleDelete(blog.id)}><MdDelete/></div>
            </div>
          ))}
        </div>
      )}
      {popupInfo && <PopupBlog info={popupInfo} onClose={closePopup} />}
      {isAddPopupOpen && <AddBlogPopup onClose={closeAddPopup} />}
    </div>
  );
};

export default Blogs;
